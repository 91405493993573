<template>
  <div class="next-arrow">
    <a :href="'#' + to" v-smooth-scroll="{ offset: -45 }">
      <svg svg-inline="" class="next-arrow__svg" role="presentation" focusable="false" fill="#ea580c" width="100pt" height="100pt" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><path d="M50 97.5c26.199 0 47.5-21.301 47.5-47.5S76.199 2.5 50 2.5 2.5 23.801 2.5 50 23.801 97.5 50 97.5zm0-88.199C72.398 9.301 90.699 27.5 90.699 50S72.398 90.699 50 90.699 9.301 72.398 9.301 50 27.602 9.301 50 9.301z"/><path d="M47.602 63.5c.602.602 1.5 1 2.398 1s1.801-.398 2.399-1l18.3-18.301c1.302-1.3 1.302-3.5 0-4.8-1.3-1.302-3.5-1.302-4.8 0l-15.898 15.8L34.2 40.4c-.7-.7-1.5-1-2.398-1-.899 0-1.801.3-2.399 1-1.3 1.3-1.3 3.5 0 4.8z"/></svg>
    </a>
  </div>
</template>

<script>
export default {
  name: 'next-arrow',

  props: {
    to: String
  }
}
</script>

<style scoped lang="scss">
@import '../../scss/variables';

.next-arrow {
  text-align: center;

  a {
    display: block;
    color: $basic-orange;
    opacity: 0.4;
    transition: 0.25s ease-in-out;

    &:hover,
    &:focus {
      opacity: 1;

      .next-arrow__text {
        opacity: 1;
      }
    }
  }

  &__svg {
    width: 40px;
    height: 40px;
  }

  &__text {
    opacity: 0;
    transition: 0.25s ease-in-out;
    font-size: 1.25rem;
    margin-left: 1rem;
  }
}
</style>
